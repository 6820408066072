<template>
    <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" v-model="dialog">
        <v-card>
            <!-- <div class="title_body">
            <span class="text_title">{{ title }}</span>
            <span class="btn_close">
                <v-btn icon small @click="dialog = false">
                    <v-icon dark> {{ icons.mdiClose }} </v-icon>
                </v-btn>
            </span>
        </div> -->
            <BarTitleDialog :title="title" @close="close" />

            <v-card-text>
                <v-container>
                    <v-card-actions>
                        <v-row>
                            <v-col class="text-right">

                                <v-btn x-large color="blue darken-1" class="mr-2" text @click="close">
                                    Fechar
                                </v-btn>
                                <v-btn x-large :loading="loading" color="primary" @click="save">
                                    Salvar
                                </v-btn>
                            </v-col>

                        </v-row>
                    </v-card-actions>

                    <v-row no-gutters class="mt-2">
                        <v-col cols="4">
                            <v-text-field v-model="nameFantasy" outlined label="Nome Fantasia"
                                :hide-details="true"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field class="ml-2" v-model="razaoSocial" outlined label="Razão Social"
                                :hide-details="true"></v-text-field>
                        </v-col>
                        <v-col cols="">
                            <v-text-field v-mask="'##.###.###/####-##'" class="ml-2" v-model="editedItem.cpf_cnpj"
                                outlined label="CPF/CNPJ" :hide-details="true"></v-text-field>
                        </v-col>
                        <v-col cols="1">
                            <v-checkbox class="ml-2" v-model="Inactive" label="Inativo" color="primary"
                                hide-details></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row no-gutters class="mt-4">
                        <v-col>
                            <v-text-field v-model="editedItem.state_registration" outlined label="Inscrição Estadual"
                                :hide-details="true"></v-text-field>
                        </v-col>
                        <v-col>
                            <v-select class="ml-2" v-model="editedItem.situation" :items="situation_items"
                                item-value="id" item-text="name" outlined hide-details label="Situação"></v-select>
                        </v-col>
                        <v-col>
                            <v-select class="ml-2" v-model="editedItem.type" :items="type_items" item-value="id"
                                item-text="name" outlined hide-details label="Tipo"></v-select>
                        </v-col>
                        <v-col>
                            <v-select class="ml-2" v-model="editedItem.category" :items="category_items" item-value="id"
                                item-text="name" outlined hide-details label="Categoria"></v-select>
                        </v-col>
                    </v-row>


                    <v-row no-gutters>
                        <v-col cols="4">
                            <v-checkbox v-model="Incomplete_registration" class="ml-2" label="Cadastro Incompleto"
                                color="primary" hide-details></v-checkbox>
                        </v-col>
                        <v-col>
                            <v-checkbox v-model="Issue_nfc_e" label="Emite NFC-e" color="primary"
                                hide-details></v-checkbox>
                        </v-col>
                        <v-col>
                            <v-checkbox v-model="Issue_cf_e_sat" label="Emite CF-e-SAT" color="primary"
                                hide-details></v-checkbox>
                        </v-col>

                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="4">
                            <v-checkbox v-model="Room_night" class="ml-2" label="Programa de fidelidade por room night"
                                color="primary" hide-details></v-checkbox>
                        </v-col>
                        <v-col>
                            <v-checkbox v-model="Block_release" label="Bloquear Lançamento" color="primary"
                                hide-details></v-checkbox>
                        </v-col>
                        <v-col>
                            <v-checkbox v-model="Cob" label="COB" color="primary" hide-details></v-checkbox>
                        </v-col>

                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card flat outlined>
                                <v-tabs v-model="tab">
                                    <v-tab>Endereço</v-tab>
                                    <v-tab>Tarifas</v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="tab">
                                    <!-- ENDEREÇO -->
                                    <v-tab-item>
                                        <v-card flat class="card-tab">
                                            <v-card-text>
                                                <v-row no-gutters class="mt-2">
                                                    <v-col cols="5">
                                                        <v-text-field v-model="editedItem.address" outlined hide-details
                                                            label="Endereço"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="2">
                                                        <v-text-field v-model="editedItem.number" outlined hide-details
                                                            label="Número" class="ml-2"></v-text-field>
                                                    </v-col>
                                                    <v-col class="ml-2">
                                                        <v-text-field v-model="editedItem.complement" outlined
                                                            hide-details label="Complemento"></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters class="mt-2">
                                                    <v-col cols="3">
                                                        <v-text-field v-model="editedItem.neighborhood" outlined
                                                            hide-details label="Bairro"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="3">
                                                        <v-select class="ml-2" v-model="editedItem.country_id"
                                                            :items="country_items" item-value="id" item-text="name"
                                                            outlined hide-details label="País"></v-select>
                                                    </v-col>
                                                    <v-col class="ml-2" cols="3">
                                                        <v-text-field v-mask="'##.###-###'" v-model="editedItem.zipcode"
                                                            outlined hide-details label="CEP"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="">
                                                        <v-select class="ml-2" @change="loadCities()"
                                                            v-model="editedItem.state_id" :items="state_items"
                                                            item-value="id" item-text="name" outlined hide-details
                                                            label="UF"></v-select>
                                                    </v-col>
                                                </v-row>

                                                <v-row no-gutters class="mt-2">

                                                    <v-col cols="3">
                                                        <v-select v-model="editedItem.city_id" :items="city_items"
                                                            item-value="id" item-text="name" outlined hide-details
                                                            label="Cidade"></v-select>
                                                    </v-col>
                                                    <!-- <v-col class="ml-2" cols="2">
                                                    <v-text-field v-model="editedItem.ibge_code"  outlined hide-details label="Cod. IBGE"></v-text-field>
                                                </v-col> -->
                                                    <v-col cols="2">
                                                        <v-text-field v-mask="'(##)####-####'" class="ml-2"
                                                            v-model="editedItem.home_phone" outlined hide-details
                                                            label="Fone Res."></v-text-field>
                                                    </v-col>
                                                    <v-col cols="2" class="ml-2">
                                                        <v-text-field v-mask="'(##)####-####'"
                                                            v-model="editedItem.commercial_phone" outlined hide-details
                                                            label="Fone Com."></v-text-field>
                                                    </v-col>
                                                    <v-col class="ml-2">
                                                        <v-text-field v-mask="'(##)#####-####'"
                                                            v-model="editedItem.cell_phone" outlined hide-details
                                                            label="Celular"></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters class="mt-2">

                                                    <v-col cols="2">
                                                        <v-text-field v-mask="'(##)####-####'"
                                                            v-model="editedItem.fax_phone" outlined hide-details
                                                            label="Fax"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-text-field class="ml-2" v-model="email" outlined hide-details
                                                            label="Email"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="">
                                                        <v-text-field class="ml-2" v-model="editedItem.home_page"
                                                            outlined hide-details label="Home Page"></v-text-field>
                                                    </v-col>
                                                    <!-- <v-col>
                                                    <v-text-field class="ml-2" v-model="editedItem.ibge"  outlined hide-details label="IBGE"></v-text-field>
                                                </v-col> -->
                                                </v-row>
                                                <v-row no-gutters class="mt-2">


                                                </v-row>
                                                <v-row no-gutters class="mt-2">

                                                </v-row>

                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                                    <v-tab-item>
                                        <v-card flat class="card-tab">
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="4">
                                                        <SelectTariff v-model="editedItem.tariff_id" />
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                                </v-tabs-items>

                            </v-card>
                        </v-col>
                    </v-row>

                </v-container>

            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>

import {
    mdiClose,
    mdiDeleteOutline,
    mdiPencilOutline,
    mdiPlus
} from '@mdi/js'

import {
    mapActions,
    mapGetters
} from 'vuex'

import BarTitleDialog from '@/components/BarTitleDialog.vue'
import SelectTariff from '@/components/SelectTariff.vue'

export default {

    components: {
        BarTitleDialog,
        SelectTariff,
    },
    props: {
        value: {
            type: Boolean
        },
        in_data: {
            type: Object
        }, //recebe os dados do hospede
        title: {
            type: String
        }

    },
    data: () => ({
        dialogDelete: false,
        dialog: false,
        loading: false,
        country_items: [],
        state_items: [],
        city_items: [],
        headers: [{
            text: 'ID',
            align: 'start',
            value: 'id',
        },
        {
            text: 'PERFIL',
            value: 'name'
        },
        {
            text: '',
            align: 'end',
            value: 'actions',
            sortable: false,
        },
        ],
        familiars: [],
        editedItem: {
            razao_social: '',
            fantasy_name: '',
            incomplete_registration: 'not',
            cpf_cnpj: '',
            situation: '',
            type: '',
            inactive: 'not',
            issue_nfc_e: 'not',
            issue_cf_e_sat: 'not',
            room_night: 'not',
            block_release: 'not',
            cob: 'not',
            address: '',
            number: '',
            neighborhood: '',
            country_id: '',
            zipcode: '',
            state_id: '',
            city_id: '',
            ibge_code: '',
            home_phone: '',
            commercial_phone: '',
            cell_phone: '',
            fax_phone: '',
            email: '',
            home_page: '',
            ibge: '',
        },
        defaultItem: {
            razao_social: '',
            fantasy_name: '',
            incomplete_registration: 'not',
            cpf_cnpj: '',
            situation: '',
            type: '',
            inactive: 'not',
            issue_nfc_e: 'not',
            issue_cf_e_sat: 'not',
            room_night: 'not',
            block_release: 'not',
            cob: 'not',
            address: '',
            number: '',
            neighborhood: '',
            country_id: '',
            zipcode: '',
            state_id: '',
            city_id: '',
            ibge_code: '',
            home_phone: '',
            commercial_phone: '',
            cell_phone: '',
            fax_phone: '',
            email: '',
            home_page: '',
            ibge: '',
        },
        situation_items: [{
            id: 1,
            name: 'Faturamento livre'
        },
        {
            id: 2,
            name: 'Faturamento bloqueado'
        }

        ],
        type_items: [{
            id: 1,
            name: 'Empresa'
        }],
        category_items: [{
            id: 1,
            name: 'Privada'
        }],
        options: {},
        tab: null,
        icons: {
            mdiPencilOutline,
            mdiDeleteOutline,
            mdiPlus,
            mdiClose,
        },
    }),

    watch: {
        value(val) {
            this.dialog = val
            if (val) {
                this.inicialize()
            }
        },
        dialog(val) {
            this.$emit('input', val)
            if (!val) {
                this.close();
            }
        },
        in_data(val) {
            this.editedItem = {
                ...val
            }
        }

    },

    created() {

    },

    computed: {
        ...mapGetters('guest', ['getFamiliars']),
        nameFantasy: {
            get() {
                return this.editedItem.fantasy_name
            },
            set(val) {
                this.editedItem.fantasy_name = val[0].toUpperCase() + val.substring(1)
            }
        },
        razaoSocial: {
            get() {
                return this.editedItem.razao_social
            },
            set(val) {
                this.editedItem.razao_social = val[0].toUpperCase() + val.substring(1)
            }
        },
        email: {
            get() {
                return this.editedItem.email
            },
            set(val) {
                this.editedItem.email = val.toLowerCase()
            }
        },

        Inactive: {
            get() {

                if (this.editedItem.inactive == 'yes') {
                    return true
                } else {
                    return false
                }
            },
            set(val) {

                if (val == true) {
                    this.editedItem.inactive = 'yes'
                } else {
                    this.editedItem.inactive = 'not'
                }
            }

        },
        Issue_nfc_e: {
            get() {

                if (this.editedItem.issue_nfc_e == 'yes') {
                    return true
                } else {
                    return false
                }
            },
            set(val) {

                if (val == true) {
                    this.editedItem.issue_nfc_e = 'yes'
                } else {
                    this.editedItem.issue_nfc_e = 'not'
                }
            }

        },
        Issue_cf_e_sat: {
            get() {
                if (this.editedItem.issue_cf_e_sat == 'yes') {
                    return true
                } else {
                    return false
                }
            },
            set(val) {

                if (val == true) {
                    this.editedItem.issue_cf_e_sat = 'yes'
                } else {
                    this.editedItem.issue_cf_e_sat = 'not'
                }
            }

        },
        Room_night: {
            get() {
                if (this.editedItem.room_night == 'yes') {
                    return true
                } else {
                    return false
                }
            },
            set(val) {

                if (val == true) {
                    this.editedItem.room_night = 'yes'
                } else {
                    this.editedItem.room_night = 'not'
                }
            }
        },
        Block_release: {
            get() {
                if (this.editedItem.block_release == 'yes') {
                    return true
                } else {
                    return false
                }
            },
            set(val) {

                if (val == true) {
                    this.editedItem.block_release = 'yes'
                } else {
                    this.editedItem.block_release = 'not'
                }
            }
        },
        Cob: {
            get() {
                if (this.editedItem.cob == 'yes') {
                    return true
                } else {
                    return false
                }
            },
            set(val) {

                if (val == true) {
                    this.editedItem.cob = 'yes'
                } else {
                    this.editedItem.cob = 'not'
                }
            }
        },
        Incomplete_registration: {
            get() {
                if (this.editedItem.incomplete_registration == 'yes') {
                    return true
                } else {
                    return false
                }
            },
            set(val) {

                if (val == true) {
                    this.editedItem.incomplete_registration = 'yes'
                } else {
                    this.editedItem.incomplete_registration = 'not'
                }
            }
        },

    },

    methods: {
        ...mapActions('company', ['store', 'update']),
        ...mapActions('select', ['get_countries', 'get_states', 'get_cities']),
        inicialize() {
            this.get_countries().then((response) => {
                this.country_items = response.data
            });

            this.get_states().then((response) => {
                this.state_items = response.data
            });

        },
        loadCities() {
            const state = this.state_items.find(state => state.id == this.editedItem.state_id) // pega o objeto estado
            this.get_cities(state.code).then((response) => {
                this.city_items = response.data
            })
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = {
                    ...this.defaultItem
                }

            })

        },
        save() {
            this.loading = true

            if (this.editedItem.id) {
                this.update(this.editedItem)
                    .then(response => {
                        const guest = response.data
                        this.close()
                        this.$emit('out_data', guest)
                        this.$toast.success('Salvo com sucesso !')
                    })
                    .catch()
                    .finally(() => {
                        this.loading = false
                    })
            } else {

                this.store(this.editedItem)
                    .then(response => {
                        const guest = response.data
                        this.close()
                        this.$toast.success('Salvo com sucesso !')
                        this.$emit('out_data', guest)
                    })
                    .catch()
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
    },
}
</script>

<style scoped>
.card-tab {
    min-height: 320px;
    min-width: 736px;
}

.tool_table_fam {
    height: 50px;
}
</style>
